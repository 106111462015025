// ChatMainDialogHeaderGraph.jsx
import React, { useState, useRef, useEffect } from 'react';
import { ButtonGroup, Button, } from 'react-bootstrap';
import FileUpload from './file_load.jsx';
import { chatMediaSuno, fileTranslator, singleFileUpload, nonUpload, showParam, getImageUrlChatClassName, chatImageMJ } from './config.jsx';
import SwitchSuno from './chat_main_dialog_header_tools_suno.jsx';

function ChatMainDialogHeaderTools({ 
  uid, model, chatClassName, onFileLoadDone, toolButtons, onShowFiles,
  onShowMJParam, onShowExpand, onSetParam
}) {
  const [selectedButton, setSelectedButton] = useState(null);
  const fileUploadRef = useRef(); // 创建一个引用
  const [toolIcon, setToolcon] = useState("");
  const [showMJButton, setShowMJButton] = useState(false);
  const [showLoadButton, setShowLoadButton] = useState(false);
  const [showFileButton, setShowFileButton] = useState(false);
  const [showImageExpand, setShowImageExpand] = useState(false);
  const [maxFiles, setMaxFiles] = useState(9);
  const [showSunoTools, setShowSunoTools] = useState(false);
  const [fileType, setFileType] = useState("image")

  const onSetParaSuno = (param) => {
    onSetParam(param);
  };

  const handleFileUpload = () => {
    setSelectedButton("fileupload");
    fileUploadRef.current.click(); // 触发文件选择框
  };

  useEffect (() => {
    if(toolButtons && toolButtons !== "") {
      switch (toolButtons.toLowerCase()) {
        case "image":
          setToolcon("bi bi-image");
          break;
        case "word":
          setToolcon("bi bi-file-word");
          break;
        case "excel":
          setToolcon("bi bi-file-excel");
          break;
        case "txt":
          setToolcon("bi bi-file-text")
          break;
        case "media":
          setToolcon("bi bi-music-note-list")
          break;
        default:
          setToolcon("")
          break;
      };
    };
  }, [toolButtons])

  useEffect (() => {
    if(chatClassName && chatClassName !== "") {
      if(singleFileUpload.includes(chatClassName)) {
        setMaxFiles(1);
      } else {
        setMaxFiles(9);
      };

      if(fileTranslator.includes(chatClassName)) {
        setFileType("translator");
      } else {
        setFileType("image");
      };

      if(showParam.includes(chatClassName)) {
        setShowMJButton(true);
      } else {
        setShowMJButton(false);
      };

      if(getImageUrlChatClassName.includes(chatClassName)) {
        setShowFileButton(true);
      } else {
        setShowFileButton(false);
      };

      if(nonUpload.includes(chatClassName)) {
        setShowLoadButton(false);
      } else {
        setShowLoadButton(true);
      };

      if(chatImageMJ.includes(chatClassName)) {
        setShowImageExpand(true);
      } else {
        setShowImageExpand(false);
      };

      if(chatMediaSuno.includes(chatClassName)) {
        setShowSunoTools(true);
      } else {
        setShowSunoTools(false);
      };
    };
  }, [chatClassName])

  return (
    <>
      {/* Upload 按钮 */}
      { showLoadButton && (
        <ButtonGroup className="mb-3 me-3">
          <Button
            variant='outline-secondary'
            onClick={handleFileUpload}
            className='border-0'
            hidden={model === "o1-mini"}
          >
            <i className="bi bi-folder2-open"></i>
          </Button>
          { showFileButton && (
            <Button variant='outline-secondary' className='border-0' 
              onClick={onShowFiles}
            >
              <i className={toolIcon}></i>
            </Button>
          )}
        </ButtonGroup>
      )}

      {/* MJ图片扩展功能 */}
      { showImageExpand && (
        <ButtonGroup className="mb-3">
          <Button variant='outline-secondary' className='border-0' onClick={onShowExpand}>
            <i className="bi bi-image"></i>
          </Button>
        </ButtonGroup>
      )}
        
      {/* MJ 参数按钮 */}
      { showMJButton && (
        <ButtonGroup className="mb-3">
          <Button variant='outline-secondary' className='border-0' onClick={onShowMJParam}>
            <i className="bi bi-gear-fill"></i>
          </Button>
        </ButtonGroup>
      )}
      
      { showSunoTools && (        
        <SwitchSuno onSetParaSuno={onSetParaSuno} onShowSunoExpand={onShowExpand}/>
      )}

      <FileUpload
        uid={uid}
        ref={fileUploadRef}
        fileType={fileType}
        onFileSelect={onFileLoadDone}
        chatClassName={chatClassName}
        maxFiles={maxFiles}
        container={fileType}
      />
    </>
  );
}

export default ChatMainDialogHeaderTools;
